import React from 'react'
import App, { Container } from 'next/app'
//import GlobalStyle from '../static/styles/global-styles'
import '../static/scss/index.scss'

import { I18nextProvider, reactI18nextModule, initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translation_en from "./../static/locale/en/translation.json"
import translation_es from "./../static/locale/es/translation.json"

import { IpService  } from './../services'

i18next
    .use(reactI18nextModule)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .init({
      debug: false,
      interpolation: { escapeValue: false },  // React already does escaping
      lng: 'es',                              // use en if detected lng is not available
      fallbackLng: 'es',                      // language to use
      resources: {
          en: {
              translation: translation_en
          },
          es: {
              translation: translation_es
          },
      },
      react: {
        wait: true
      }
    })

class MyApp extends App {

  // static async getInitialProps({ Component, router, ctx }) {
  //
  //   //let locationProps = {}
  //
  //   return new Promise(function(resolve, reject) {
  //
  //     IpService.getClientIp().then((response) => {
  //
  //       // let lang = 'es';
  //       //
  //       // if(response.location){
  //       //   if(response.location.languages){
  //       //     if(response.location.languages.length > 0){
  //       //       lang = response.location.languages[0].code;
  //       //     }
  //       //   }
  //       // }
  //       //
  //       // let toResponse = Object.assign({ lang: lang }, pageProps);
  //
  //       resolve({ client: response });
  //
  //     });
  //
  //   });
  //
  // }

  componentWillMount() {

    //console.log(this.props);

    // if(this.props.client.location){
    //   if(this.props.client.location.languages){
    //     if(this.props.client.location.languages.length > 0){
    //       console.log(this.props.client.location.languages[0].code);
    //       i18next.changeLanguage(this.props.client.location.languages[0].code);
    //     }
    //   }
    // }

  }

  // handleIp = () => {
  //
  // }

  render () {

    const { Component, pageProps } = this.props;

    return (
      <React.Fragment>
        <I18nextProvider i18n={ i18next }>
          <Container className="test">
            <Component { ...pageProps } />
          </Container>
        </I18nextProvider>
      </React.Fragment>
    )

  }
}

export default MyApp
